import * as preact from 'preact'
import Footer from 'compositions/Footer/FooterChunk'
import singleSpaPreact from 'single-spa-preact'
import { setPublicPath } from 'systemjs-webpack-interop'


setPublicPath('@smartsarov/footer')

const preactLifecycles = singleSpaPreact({
  preact,
  rootComponent: Footer,
})


export const bootstrap = preactLifecycles.bootstrap
export const mount = preactLifecycles.mount
export const unmount = preactLifecycles.unmount
